<template>
  <v-app class="">
    <v-navigation-drawer
      color="secondary"
      dark
      v-model="sidebarMenu"
      app
      :mini-variant="mini"
      mini-variant-width="122"
      width="220"
      :permanent="$vuetify.breakpoint.mdAndUp ? true : false"
    >
      <v-layout column justify-center justify-space-between fill-height>
        <div>
          <v-list-item>
            <v-list-item-title class="text-center">
              <img
                v-if="mini"
                src="/img/icon.png"
                alt="alt"
                width="90"
                class="pt-15"
              />
              <img
                v-else
                src="/img/icon.png"
                alt="alt"
                width="120"
                class="pt-10"
              />
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-show="$vuetify.breakpoint.mdAndUp">
            <small class="font-weight-bold white--text"> Transformación </small>
          </v-list-item>
        </div>
        <v-list rounded>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                active-class="primary darken-1 primary--text text--lighten-4"
                link
                to="/"
              >
                <v-list-item-icon>
                  <v-icon color="">mdi-home-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="">Inicio</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span v-if="$vuetify.breakpoint.smAndUp">Inicio</span>
          </v-tooltip>
          <v-tooltip right v-for="item in items" :key="item.title">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                link
                :to="item.href"
                active-class="primary darken-1 primary--text text--lighten-4"
              >
                <v-list-item-icon>
                  <v-icon color="">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span v-if="$vuetify.breakpoint.smAndUp">{{ item.title }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                link
                to="/configuracion"
                active-class="primary darken-1 primary--text text--lighten-4"
              >
                <v-list-item-icon>
                  <v-icon color=""> mdi-office-building-cog-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="">
                    Configuración
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span v-if="$vuetify.breakpoint.smAndUp"> Configuración </span>
          </v-tooltip>
        </v-list>
        <div>
          <v-list-item v-if="$vuetify.breakpoint.mdAndUp" class="pb-10">
            <v-menu right min-width="100px" rounded nudge-right="80">
              <template v-slot:activator="{ on }">
                <v-avatar
                  rounded
                  color="primary lighten-2"
                  v-on="on"
                  class="clickable"
                >
                  <span class="white--text">{{ nameInitial }}</span>
                </v-avatar>
              </template>
              <v-card class="pa-5">
                <v-list-item-content class="justify-center">
                  <div class="mx-auto text-center">
                    <v-avatar color="primary lighten-1">
                      <span class="white--text text-h6">{{ nameInitial }}</span>
                    </v-avatar>
                    <h5 class="py-3">{{ name }}</h5>
                    <small class="font-weight-medium primary--text">
                      {{ establishmentName }}
                    </small>
                    <v-divider class="my-3"></v-divider>
                    <v-btn
                      @click="logout"
                      elevation="0"
                      color="secondary"
                      rounded
                    >
                      cerrar sesión
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-card>
            </v-menu>
          </v-list-item>
        </div>
      </v-layout>
    </v-navigation-drawer>
    <v-app-bar app elevation="0" color="transparent">
      <v-toolbar
        v-if="$vuetify.breakpoint.xs"
        class="mt-7"
        :class="scroll ? 'scrolling' : 'elevation-0'"
        color="white"
        elevation="4"
        rounded
      >
        <div class="">
          <v-btn icon @click.stop="sidebarMenu = !sidebarMenu">
            <v-icon>
              {{ !sidebarMenu ? "mdi-forwardburger" : "mdi-backburger" }}
            </v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <small class="font-weight-bold primary--text">Transformación</small>
        </div>
        <v-spacer></v-spacer>
        <div class="">
          <v-menu bottom min-width="100px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-chip
                outlined
                class="ma-2"
                color="support lighten-5"
                text-color="support"
                v-on="on"
              >
                <v-avatar left color="primary lighten-2">
                  <span class="white--text">{{ nameInitial }}</span>
                </v-avatar>
                {{ firstName }}
                <v-icon right> mdi-chevron-down </v-icon>
              </v-chip>
            </template>
            <v-card class="pa-5">
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="primary lighten-1">
                    <span class="white--text text-h6">{{ nameInitial }}</span>
                  </v-avatar>
                  <h5 class="py-3">{{ name }}</h5>
                  <small class="font-weight-medium primary--text">
                    {{ establishmentName }}
                  </small>
                  <v-divider class="my-3"></v-divider>
                  <v-btn
                    @click="logout"
                    elevation="0"
                    color="secondary"
                    rounded
                  >
                    cerrar sesión
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
        </div>
      </v-toolbar>
      <v-toolbar
        v-else
        class="mt-7"
        :class="scroll ? 'scrolling' : 'elevation-0'"
        color="white"
        elevation="4"
        rounded
      >
        <v-spacer></v-spacer>
        <div>
          <img
            v-if="mini"
            src="/img/logo_saf_agritecgeo.png"
            alt="alt"
            width="128"
            class="pt-2"
          />
        </div>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-app-bar>
    <v-main class="">
      <v-container class="px-sm-5 fill-height" fluid>
        <v-row class="pt-1 fill-height">
          <v-col>
            <transition name="fade">
              <v-dialog
                :value="loading && !showAlert"
                hide-overlay
                persistent
                width="300"
                v-if="loading"
              >
                <v-card color="primary" dark>
                  <v-card-text>
                    Cargando Establecimiento
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <div v-else class="radius-ui">
                <slot />
              </div>
            </transition>
            <v-dialog :value="showAlert" persistent max-width="290">
              <v-card color="warning">
                <v-card-title>
                  <v-icon large left color="white"> mdi-alert </v-icon>
                  <span class="text-h6 font-weight-light white--text">
                    Advertencia
                  </span>
                </v-card-title>
                <v-card-text>
                  <span class="white--text">
                    Seleccione un establecimiento antes de continuar
                  </span>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text :href="url">Aceptar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      padless
      class="mt-10 mb-5"
      :class="$vuetify.breakpoint.mdAndUp ? 'footer-disagro' : ''"
      rounded
      color="white"
    >
      <v-row class="pa-2" no-gutters>
        <v-col
          order="2"
          order-md="1"
          cols="12"
          md="6"
          class="d-flex align-center justify-center justify-md-start pl-md-10"
        >
          <div class="font-weight-medium support--text">
            <span class="pl-md-4"> &copy; {{ new Date().getFullYear() }} </span>
            <strong class="primary--text">DISAGRO</strong>
          </div>
        </v-col>
        <v-col
          order="1"
          order-md="2"
          cols="12"
          md="6"
          class="d-flex justify-center d-md-block justify-md-end"
        >
          <div class="float-md-right pr-md-10">
            <img src="/img/icon.png" alt="alt" height="50" />
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PrincipalLayout",
  data: () => ({
    sidebarMenu: false,
    miniVariant: false,
    items: [],
    active: false,
    dialog: false,
    showAlert: false,
    scroll: false,
  }),
  computed: {
    ...mapState({
      toggleMini: (state) => state.config.toggleMini,
      businessUnit: (state) => state.config.businessUnit,
      company: (state) => state.config.company,
      establishment: (state) => state.config.establishment,
      name: (state) => state.config.name,
      loading: (state) => state.config.loading,
      establishmentName: (state) => state.config.establishmentName,
    }),
    mini() {
      return this.$vuetify.breakpoint.smAndDown ? false : this.toggleMini;
    },
    color() {
      return !this.$vuetify.theme.dark ? "grey" : "grey darken-4";
    },
    url() {
      return `${process.env.VUE_APP_LOGIN_URL}/back?change=sysid&sysid=PRO`;
    },
    nameInitial() {
      return this.name.charAt(0);
    },
    firstName() {
      return this.name.split(" ")[0];
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    changeMenu() {
      this.$store.dispatch("config/changeMenu", !this.toggleMini);
    },
    loadEstablishmment(val) {
      if (val === null && this.company !== null) {
        this.items = [
          {
            title: "Establecimientos",
            href: "/establecimientos",
            icon: "mdi-store",
          },
        ];
        return;
      }
      this.loadMenu();
    },
    loadMenu() {
      if (this.establishment !== null) {
        this.items = [
          {
            title: "Ordenes de trabajo",
            href: "/ordenes-trabajo",
            icon: "mdi-clipboard-text-clock-outline",
          },
          {
            title: "Reportes",
            href: "/reportes",
            icon: "mdi-chart-box-outline",
          },
        ];
      }
    },
    logout() {
      const url = location.origin.includes("localhost")
        ? process.env.VUE_APP_LOGIN_URL
        : location.origin.replace("planilla", "login");
      localStorage.clear();
      window.open(`${url}/logout`, "_self");
    },
    scrolling() {
      this.scroll =
        document.body.scrollTop > 10 || document.documentElement.scrollTop > 10;
    },
  },
  watch: {
    establishment(val) {
      this.loadEstablishmment(val);
    },
    loading() {
      this.dialog = this.loading;
    },
  },
  created() {
    if (!this.establishment || !this.businessUnit || !this.company) {
      this.showAlert = true;
      return;
    }
    if (this.establishment && this.businessUnit && this.company) {
      this.loadMenu();
      return;
    }
  },
  mounted() {
    window.onscroll = () => {
      this.scrolling();
    };
  },
};
</script>
<style scoped src="@/assets/css/globals.css"></style>
<style scoped>
.footer-disagro {
  margin-left: 145px;
  width: calc(100% - 168px);
}
.scrolling {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
.clickable {
  cursor: pointer !important;
}

.element-active {
  background-color: #007336 !important;
  border-color: #007336 !important;
}
</style>
